.app__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: calc(100vh - 5rem);
}

@media screen and (max-width: 650px) {
  .app__header {
    flex-direction: column;
    height: auto;
  }
  .small__button {
    font-size: 5rem;
  }

  .palyazat__container {
    display: none;
  }
}
