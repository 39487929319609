.updatePriceContainer {
  width: 100%;
  padding: 2rem 8rem;
  height: calc(100vh - 5rem);
}

@media screen and (max-width: 650px) {
  .newMenuContainer {
    width: 100%;
    padding: 2rem 2rem;
  }
}
