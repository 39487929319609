.table {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 6rem);
  margin: 0 2rem;
  padding: 1rem;
  background-color: var(--color-black);
  border-radius: 0.5rem;
  width: 95%;
}

.addButton {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0.5rem;
}

.addButton .menuAdd {
  font-size: 18px;
  text-transform: uppercase;
  color: var(--color-crimson);
  margin-right: 2rem;
}

.menuAdd:hover {
  color: var(--color-golden);
}
