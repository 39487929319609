.app__footer {
  width: 100%;
  height: calc(100vh - 5rem);
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: var(--color-black);
  padding-top: 0;
}

.app__footer-links {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 5rem;
  padding: 0 2rem;
}

.app__footer-links_contact,
.app__footer-links_logo,
.app__footer-links_work {
  flex: 1;
  margin: 1rem;
  text-align: center;
}
.app__footer-links_logo {
  order: 2;
}
.app__footer-links_contact {
  order: 1;
}
.app__footer-links_work {
  order: 3;
}

.app__footer-logo {
  font-size: 2.8rem;
  color: var(--color-golden);
  font-family: var(--font-decor);
  text-align: center;
  margin-bottom: 0.5rem;
}

.app__footer-links_logo p {
  font-size: 1.4rem;
}

.app__footer-links_work p:nth-child(2n + 1) {
  margin-bottom: 1rem;
}

.app__footer-links_icons {
  margin-top: 0.5rem;
}

.app__footer-links_icons svg {
  color: var(--color-white);
  font-size: 2rem;
  margin: 0.5rem;
  cursor: pointer;
}

.app__footer-links_icons:hover svg {
  color: var(--color-golden);
}

.app__footer-headtext {
  font-family: var(--font-alt);
  color: var(--color-golden);
  font-size: 2rem;
  margin-bottom: 0.5rem;
  font-weight: 400;
  letter-spacing: 0.04em;
  margin-bottom: 1rem;
}

.app__footer-links_contact p {
  cursor: pointer;
}

.app__footer-links_contact p:hover {
  color: var(--color-golden);
}

.footer__copyright {
  /* margin-top: 3rem; */
}

.footer__copyright .p__span {
  color: var(--color-ska);
  font-size: 1.2rem;
  cursor: pointer;
}

.footer__copyright:hover .p__span {
  color: var(--color-grey);
}

@media screen and (max-width: 650px) {
  .app__footer {
    height: auto;
  }

  .app__footer-links {
    align-items: center;
    flex-direction: column;
    margin-bottom: 0;
  }

  .app__footer-logo {
    font-size: 2.2rem;
  }

  .app__footer-links_logo p {
    font-size: 1.2rem;
  }

  .app__footer-headtext {
    font-size: 1.3rem;
  }

  .app__footer-links_icons svg {
    font-size: 1.6rem;
  }

  .app__footer-links_logo {
    order: 1;
    margin-top: 2rem;
  }
  .app__footer-links_contact {
    order: 2;
  }
  .app__footer-links_work {
    order: 3;
  }
  .footer__copyright .p__span {
    font-size: 0.8rem;
  }
}
