.container {
  height: calc(100vh - 5rem);
}

.box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  margin: auto;
  padding: 5rem 2rem;
  background-color: var(--color-black);
  border-radius: 0.5rem;
  width: 35%;
}

.box .loginButton {
  margin-top: 5rem;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  color: var(--color-golden);
}
