.app__navbar {
  width: 100%;
  height: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--color-black);
  padding: 0.5rem 2rem;
}

.app__navbar-logo-container {
  display: flex;
  align-items: center;
}

.app__navbar-logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 3.75rem;
  color: var(--color-golden);
  font-family: var(--font-decor);
  padding: 0 1.1rem 0 1rem;
}

.app__navbar-logo-container .icon {
  width: 3rem;
}

.app__navbar-links {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-base);
  list-style: none;
  margin-right: 20rem;
}

.app__navbar-links li {
  text-transform: uppercase;
  margin: 0 1rem;
  cursor: pointer;
  font-size: 1.3rem;
  transition: 0.4s ease;
}

.app__navbar-links li:hover {
  color: var(--color-golden);
}

.app__navbar-smallscreen {
  display: none;
}

.app__navbar-smallscreen_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--color-black);
  transition: 0.5s ease;
  flex-direction: column;
  z-index: 10;
}

.app__navbar-smallscreen_overlay .overlay__close {
  font-size: 27px;
  color: var(--color-golden);
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}

.app__navbar-smallscreen_links {
  list-style: none;
  font-family: var(--font-alt);
}

.app__navbar-smallscreen_links li {
  margin: 3rem;
  cursor: pointer;
  color: var(--color-golden);
  text-transform: uppercase;
  font-size: 1.6rem;
  text-align: center;
  transition: 0.4s ease;
}

.app__navbar-smallscreen_links li:hover {
  color: var(--color-white);
}

.palyazat__container {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
}

.palyazat__logo {
  width: 280px;
}

@media screen and (max-width: 1200px) {
  .app__navbar-links {
    display: none;
  }
  .app__navbar-smallscreen {
    display: flex;
  }
}

@media screen and (max-width: 650px) {
  .app__navbar {
    padding: 1rem;
  }
  .app__navbar-logo {
    font-size: 2rem;
    padding: 0 0.5rem 0 0.25rem;
  }

  .app__navbar-logo-container .icon {
    width: 1.5rem;
  }
}
