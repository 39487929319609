.section__padding {
  padding: 4rem 4rem;
}

.flex__center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__bg {
  background: url("./assets/bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  background-attachment: fixed;
}

.app__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: calc(100vh - 5rem);
}

.app__wrapper_info {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.app__wrapper_img {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: 2rem; */
}

.app__wrapper_img-reverse {
  justify-content: flex-start;
  margin-right: 2rem;
}

.app__wrapper_img img {
  width: 72%;
}

.custom__button {
  background-color: var(--color-golden);
  color: var(--color-black);
  font-family: var(--font-base);
  font-weight: 700;
  letter-spacing: 0.04em;
  line-height: 28px;
  font-size: 1.2rem;
  padding: 0.5rem 1.5rem;
  border-radius: 1px;
  border: none;
  outline: none;
  cursor: pointer;
  text-transform: uppercase;
}

.app__header {
  font-family: var(--font-base);
  color: var(--color-golden);
  font-size: 5rem;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}

.app__header-h1 {
  font-size: 4rem;
  font-family: var(--font-base);
  color: var(--color-golden);
  font-size: 4rem;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}

.p__decor {
  font-family: var(--font-decor);
  color: var(--color-white);
  font-weight: 700;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  font-feature-settings: "tnum" on, "lnum" on;
  line-height: 29.9px;
  font-size: 23px;
}

.p__alt {
  font-family: var(--font-default);
  color: var(--color-white);
  font-weight: 400;
  letter-spacing: 0.025em;
  line-height: 2.4rem;
  font-size: 1.1rem;
  text-transform: none;
}

@media screen and (max-width: 1150px) {
  .app__wrapper {
    flex-direction: column;
  }

  .app__wrapper_img {
    margin: 1rem 0 0 0;
  }

  .app__wrapper_img-reverse {
    margin: 0 0 5rem 0;
  }

  .app__wrapper_img img {
    width: 100%;
  }
}

@media screen and (max-width: 650px) {
  .app__header-h1 {
    font-size: 1.9rem;
  }
  .app__wrapper_img {
    margin-bottom: 2rem;
  }

  .custom__button {
    font-size: 1.2rem;
  }

  .p__alt {
    font-size: 0.8rem;
  }
}
