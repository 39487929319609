.card {
  background: transparent;
  text-align: center;
  color: var(--color-white);
  /* transform: scale(0.9); */
  transition: 0.5s;
  height: 100% !important;
  font-family: var(--font-default);
}
.card h1 {
  font-size: 1.8rem;
  padding: 0.5rem 0;
  text-transform: uppercase;
}

.card h2 {
  font-size: 1.7rem;
  padding-bottom: 0.5rem;
}

.card p {
  font-size: 1.5rem;
  padding: 0.5rem 0;
  font-family: var(--font-default);
}

.card .soup {
  color: var(--color-golden);
  font-size: 1.6rem;
  margin-bottom: 1rem;
}

.card-active {
  border: 1px solid var(--color-golden);
  /* transform: scale(1); */
  filter: blur(0);
}

.card-top {
  background-color: var(--color-golden);
  color: var(--color-black);
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}
.card-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: var(--color-grey);
  margin: 1rem 2rem;
}

@media screen and (max-width: 650px) {
  .card h1 {
    font-size: 1.6rem;
  }

  .card h2 {
    font-size: 1.4rem;
  }

  .card p {
    font-size: 1.3rem;
  }
}
