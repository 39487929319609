.app__slider {
  height: calc(100vh - 5rem);
}

.app__slider__container {
  display: flex;
  align-items: stretch;
  justify-content: center;
}

.app__slider-h1 {
  text-align: center;
  padding: 0 0 4rem 0;
}

.slick-track {
  display: flex !important;
}
.slick-slide {
  height: inherit !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.slick-slide > div {
  height: 100%;
  border: 1px solid var(--color-golden);
  border-radius: 1rem;
  overflow: hidden;
  width: 98%;
}

.missingMenu {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10rem;
}

.missingText {
  font-size: 6rem;
  color: var(--color-grey);
  font-weight: 300;
  font-family: var(--font-decor);
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2.5rem 0;
}

.buttonContainer .menuDownload {
  font-size: 1.65rem;
  text-transform: uppercase;
  color: var(--color-crimson);
  padding: 1rem 1.5rem;
}

.menuDownload:hover {
  color: var(--color-golden);
}

.hidden {
  display: none;
}

@media screen and (max-width: 650px) {
  .app__slider {
    height: auto;
  }
  .app__slider-h1 {
    font-size: 2.2rem;
    display: none;
  }
  .slick-slide > div {
    width: 95%;
  }
  .buttonContainer .menuDownload {
    font-size: 1.25rem;
  }
}
