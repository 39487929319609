.app__gallery {
  display: flex;
  /* align-items: center;
  justify-content: center; */
  flex-direction: row;
  background-color: var(--color-black);
  padding: 4rem 0 4rem 0;
}

.app__gallery-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-right: 10rem;
}

.app__gallery-h1 {
  font-size: 4rem;
  font-family: var(--font-base);
  color: var(--color-golden);
  font-size: 4rem;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}

.app__gallery-images {
  display: flex;
  flex-direction: row;
  max-width: 50%;
  position: relative;
}

.app__gallery-images_container {
  display: flex;
  flex-direction: row;
  width: max-content;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.app__gallery-images_container::-webkit-scrollbar {
  display: none;
}

.app__gallery-images_card {
  position: relative;
  min-width: 800px;
  height: 600px;
  margin-right: 30px;
  cursor: pointer;
}

.app__gallery-images_card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.8;
  transition: 0.3s ease;
}

.app__gallery-images_card:hover img {
  opacity: 1;
}

.app__gallery-images_arrows {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  position: absolute;
  bottom: 5%;
}

.gallery__arrow-icon {
  color: var(--color-golden);
  font-size: 2rem;
  cursor: pointer;
  background-color: var(--color-black);
  border-radius: 5px;
}

.gallery__arrow-icon:hover {
  color: var(--color-white);
}

@media screen and (min-width: 1150px) {
  .app__gallery {
    height: calc(100vh - 5rem);
  }
}

@media screen and (max-width: 1150px) {
  .app__gallery {
    flex-direction: column;
  }

  .app__gallery-images {
    max-width: 100%;
  }
}
@media screen and (max-width: 850px) {
  .app__gallery {
    padding: 4rem 2rem 4rem 2rem;
    margin-bottom: 0;
  }

  .app__gallery-images_container {
    margin-top: 3rem;
  }

  .app__gallery-content {
    width: 100%;
    padding-right: 0;
  }
}

@media screen and (max-width: 650px) {
  .app__gallery-images_container {
    flex-direction: column;
  }

  .app__gallery-h1 {
    display: none;
  }

  .app__gallery-images_card {
    min-width: 240px;
    height: auto;
    margin-bottom: 1rem;
    margin-right: 0;
  }

  .app__gallery-images_arrows {
    display: none;
  }
}
