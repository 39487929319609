.app__tender {
  height: calc(100vh - 5rem);
}

.app__tender-headtext {
  font-family: var(--font-alt);
  color: var(--color-crimson);
  font-size: 1.75rem;
  text-align: center;
  margin: 1rem 0 5rem 0;
  font-weight: 400;
  letter-spacing: 0.04em;
}

.app__tender-text {
  font-family: var(--font-alt);
  color: var(--color-crimson);
  font-size: 1.4rem;
  margin: 2rem 0;
  font-weight: 300;
  letter-spacing: 0.04em;
}

.app__tender-text span {
  color: var(--color-golden);
}

@media screen and (max-width: 650px) {
  .app__tender {
    height: auto;
  }
  .app__tender-headtext {
    font-size: 1.3rem;
    margin: 1rem 0 2rem 0;
  }
  .app__tender-text {
    font-size: 1.2rem;
    margin: 1rem 0 1rem 0;
  }
}
