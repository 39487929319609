.newMenuContainer {
  /* height: calc(100vh - 5rem); */
  width: 100%;
  padding: 2rem 8rem;
  /* display: flex; */
}

@media screen and (max-width: 650px) {
  .newMenuContainer {
    width: 100%;
    padding: 2rem 2rem;
  }
}
